import React from "react"
import { Carousel } from "react-bootstrap"
import { Link } from "gatsby"
import Slide1Left from "../images/slide1left.webp"
import Slide1LeftLow from "../images/slide1left_low.webp"
import Slide1Right from "../images/slide1right.webp"
import Slide1RightLow from "../images/slide1right_low.webp"
import Slide2 from "../images/slide2.webp"
import Slide2Low from "../images/slide2_low.webp"
import Slide3 from "../images/slide3.webp"
import Slide3Low from "../images/slide3low.webp"
import LazyImage from "./LazyImage"
import AOS from "aos"
import "aos/dist/aos.css"

export default function BSCarousel(props) {
  React.useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])

  const [index, setIndex] = React.useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      fade
      className="custom-slider"
      activeIndex={index}
      onSelect={handleSelect}
      pause={false}
    >
      <Carousel.Item style={{ opacity: index === 0 ? "1" : "0" }}>
        <div className="row" data-aos="fade-up">
          <div className="col-md-4  slideshow1left">
            <LazyImage
              lowRes={Slide1LeftLow}
              highRes={Slide1Left}
              width="100%"
              alt="First slide"
            />
          </div>
          <div className="col-md-5 ">
            <div className="pl-4 slide1text ">
              <h1>{props.carousel[0].title}</h1>
              <p className="">{props.carousel[0].description}</p>
              <Link
                to="/learningProcess"
                className="btn btn-success mt-3 py-2 slide1btn"
              >
                {props.carousel[0].button_text}
              </Link>
            </div>
          </div>
          <div className="col-lg-3 ">
            <LazyImage
              className="slide1right  slideshow1right"
              lowRes={Slide1RightLow}
              highRes={Slide1Right}
              style={{ marginTop: "20rem" }}
              width="100%"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ opacity: index === 1 ? "1" : "0" }}>
        <div className="row bg-white" data-aos="fade-up">
          <div className="col-md-12 text-center">
            <div className="px-4 mt-5 slide2text">
              <h1>{props.carousel[1].title}</h1>
              <p className="">{props.carousel[1].description}</p>
              <Link
                to="/learningProcess"
                className="btn btn-success mt-3 py-2 slide1btn"
              >
                {props.carousel[1].button_text}
              </Link>
            </div>
          </div>
          <div className="col-lg-12 mt-2">
            <LazyImage
              className="slide2"
              lowRes={Slide2Low}
              highRes={Slide2}
              width="100%"
              alt="First slide"
              data-aos="fade-up"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item style={{ opacity: index === 2 ? "1" : "0" }}>
        <div className="row d-flex slide3" data-aos="fade-up">
          <div className="col-md-2" />
          <div className="col-md-5">
            <div className="pl-4 slide3text ">
              <h1>{props.carousel[2].title}</h1>
              <p className="">{props.carousel[2].description}</p>
              <Link
                to="/learningProcess"
                className="btn btn-success mt-3 py-2 slide1btn"
              >
                {props.carousel[2].button_text}
              </Link>
            </div>
          </div>
          <div className="col-lg-5">
            <LazyImage
              className="slide3right slideshow1right"
              lowRes={Slide3Low}
              highRes={Slide3}
              style={{ marginTop: "-.5rem" }}
              width="100%"
              alt="Third slide"
            />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}
