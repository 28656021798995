import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import "../assets/style.scss"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import schoolarship from "../images/scholarship.svg"
import childLabour from "../images/child-labour.svg"
import childMarraige from "../images/child-marraige.svg"
import arrow from "../images/arrow.svg"
import greenCard from "../images/green-card.svg"
import blueCard from "../images/blue-card.svg"
import yellowCard from "../images/yellow-card.svg"
import donateCard from "../images/donate_card.svg"
import volunteerCard from "../images/volunteer_card.svg"
import partnerCard from "../images/partner_card.svg"
import redCard from "../images/red-card.svg"
import kids_running from "../images/kids_running.webp"
import kids_running_low from "../images/kids_running_low.webp"
import homeRightVector from "../images/homeRightVector.svg"
import StoryCard from "../components/StoryCard"
import LazyImage from "../components/LazyImage"
import AOS from "aos"
import "aos/dist/aos.css"
import BSCarousel from "../components/BSCarousel"

const HomePage = ({ data }) => {
  React.useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])

  return (
    <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
      <SEO title="Home" />
      <Header currentPage="home" />
      <div style={{ marginTop: "5rem" }}></div>

      {/* <Carousel carousel={data.strapiHomePage.Carousel} /> */}
      <BSCarousel carousel={data.strapiHomePage.Carousel} />

      <div style={{ marginTop: "15rem" }}></div>

      <div className="container mt-5" style={{ zIndex: 999999 }}>
        <h1>
          <b>Who we are?</b>
        </h1>

        <div className="row">
          <div className="col-md-8">
            <ReactMarkdown>{data.strapiHomePage.who_we_are}</ReactMarkdown>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="container" data-aos="fade-right">
        <div className="row text-center">
          <div className="col-lg-2 m-2 col-sm-6">
            <img src={schoolarship} alt="shoolarship" />
            <div className="text-center pt-3">
              <p class="">
                Providing
                <br />
                Schoolarship
              </p>
            </div>
          </div>
          <div className="col-lg-2 m-2 col-sm-6">
            <img src={childLabour} alt="childLabour" />
            <div className="text-center pt-3">
              <p>
                Stopping
                <br />
                Child&nbsp;Labour
              </p>
            </div>
          </div>
          <div className="col-lg-2 m-2 col-sm-6">
            <img src={childMarraige} alt="childMarraige" />
            <div className="text-center pt-3">
              <p>
                Preventing
                <br />
                Child&nbsp;Marriages
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container mt-5" data-aos="fade-up">
        <h1>
          <b>What we are doing?</b>
        </h1>
        <br />
        <br />
        <div className="row">
          <Link to="/projects?tab=2" className="col-md-3">
            <div class="story-card">
              <img src={greenCard} alt="card" />
              <div className="card-text-2">
                <h5>Ensuring a fun learning experience</h5>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
          <Link to="/projects" className="col-md-3">
            <div class="story-card">
              <img src={blueCard} alt="card" />
              <div className="card-text-2">
                <h5>Providing full free scholarships</h5>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
          <Link to="/projects?tab=2" className="col-md-3">
            <div class="story-card">
              <img src={yellowCard} alt="card" />
              <div className="card-text-2">
                <h5>Expanding the imagination of kids</h5>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
          <Link to="/projects?tab=3" className="col-md-3">
            <div class="story-card">
              <img src={redCard} alt="card" />
              <div className="card-text-2">
                <h5>Providing midday meal for proper nutrition</h5>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div
        className="container-fluid image-fluid m-0 p-0 overflow-hidden position-relative"
        data-aos="fade-up"
      >
        <div className="row">
          <div className="col-lg-12 ">
            <LazyImage
              highRes={kids_running}
              lowRes={kids_running_low}
              alt="backdrop"
              className="backdrop position-relative"
              style={{ height: "100%", objectFit: "cover" }}
            ></LazyImage>
          </div>
        </div>
      </div>

      <div className="position-relative slideshow1right" data-aos="fade-left">
        <img
          src={homeRightVector}
          class="position-absolute blobRight "
          style={{ right: 0, top: 0 }}
          alt="homeRightVector"
        ></img>
      </div>

      <div className="container " data-aos="fade-up">
        <div className="headin p-0">
          <h1>
            <b>How can you contribute?</b>
          </h1>
        </div>
        <br />

        <div className="row">
          <div className="col-md-8">
            <ReactMarkdown>
              {data.strapiHomePage.how_you_can_contribute}
            </ReactMarkdown>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <div className="container" data-aos="fade-right">
        <div className="row">
          <Link to="/donate" className="col-md-3">
            <div class="story-card">
              <img src={donateCard} alt="card" />
              <div className="card-text-2">
                <h3>Donate</h3>
                <p className="text-dark">Donate for different projects</p>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
          <Link to="/volunteer" className="col-md-3">
            <div class="story-card">
              <img src={volunteerCard} alt="card" />
              <div className="card-text-2">
                <h3>Volunteer</h3>
                <p className="text-dark">Help with your expertise</p>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
          <Link to="/partner" className="col-md-3">
            <div class="story-card">
              <img src={partnerCard} alt="card" />
              <div className="card-text-2">
                <h3>Be a partner</h3>
                <p className="text-dark">Let's work together</p>
                <img src={arrow} alt="Learn More" className="card-arrow" />
              </div>
            </div>
          </Link>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      {/* <div className="container" data-aos="fade-up">
        <h1>
          <b>Explore recent activities</b>
        </h1>
        <br />
        <div className="row">
          {data.allStrapiActivities.nodes.map(e => (
            <StoryCard
              to={"/details?id=" + e.strapiId}
              key={e.id}
              title={e.title}
              date={e.activity_date}
            />
          ))}
        </div>
      </div> */}
      <br />
      <br />
      <br />
      <br />
      <div className="container" data-aos="fade-up">
        <h1>
          <b>Swapnanagar on news</b>
        </h1>
        <br></br>
        <div className="row justify-content-center align-items-end">
          {data.strapiHomePage.newspaper_link.map((newspaper, index) => (
            <a
              href={newspaper.link}
              target="_blank"
              rel="noreferrer noopener"
              className="col-sm-3 my-4"
            >
              <img
                src={
                  index === 0
                    ? "https://adaptconvention.com/wp-content/uploads/2020/09/The_Daily_star_Logo.png"
                    : "https://iconape.com/wp-content/files/pw/132593/svg/132593.svg"
                }
                alt={newspaper.title}
                width="70%"
              ></img>
            </a>
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer data-aos="fade-right" />
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query IndexQuery {
    strapiHomePage {
      Carousel {
        button_text
        description
        id
        title
      }
      how_you_can_contribute
      id
      newspaper_link {
        id
        link
        logo {
          url
        }
        title
      }
      strapiId
      who_we_are
    }
  }
`
